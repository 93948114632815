.partners {
  background: #1e1e1e;

  &__container {
    padding-top: 100px;
    min-height: 70vh;
    max-width: 1600px;
    margin: auto;
  }

  &__title {
    color: #FFFFFF;
    font-size: 32px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 24px;
  }

  &__item {
    display: flex;
    background: #2b2b2b;
    border-radius: 40px;
    flex-direction: column;
    align-items: center;
    padding: 20px 40px;

    img {
      width: 200px;
      height: 200px;
    }

    &__title {
      color: #a8a8a8;
      font-size: 24px;
      margin-bottom: 24px;
      display: block;
    }
  }

  &__title {
    color: #FFFFFF;
    font-size: 32px;
    margin-bottom: 24px;
  }

  &__description {
    color: #a8a8a8;
    font-size: 12px;
    max-width: 300px;
    line-height: 16px;
    text-align: center;
  }

  &__caption {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}

@media only screen and (max-width: 991px) {
  .partners {
    &__container {
      padding-top: 120px;
    }
  }
}

@media only screen and (max-width: 576px) {
  .partners {
    &__list {
      grid-template-columns: 1fr;
    }
  }
}