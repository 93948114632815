.custom-progress {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}

.pin-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;

  input {
    width: 52px;
    height: 52px;
    display: flex;
    border-radius: 10px;
    background: #F5F5F5;
    border: unset;
    padding-inline: 16px;
    text-align: center;
  }
}
