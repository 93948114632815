.mobile-dropdown {
  border-radius: 12px;
  background: #ff0009;
  color: #FFFFFF;
  padding: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  height: 42px;
  white-space: nowrap;
  font-size: 14px;
}
.mobile-dropdown__button {
  cursor: pointer;
}
.mobile-dropdown svg {
  stroke: #FFFFFF;
}

@media only screen and (max-width: 991px) {
  .mobile-dropdown {
    padding: 16px 10px;
  }
}/*# sourceMappingURL=MobileDropwdown.css.map */