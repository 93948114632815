.home {
  display: flex;
  flex-direction: column;
}

.banner {
  background: #1e1e1e;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 48px;
  padding: 24px 12px;
}
.banner img {
  max-width: 750px;
}
.banner p {
  color: #ffffff;
  max-width: 388px;
  text-align: center;
  font-size: 24px;
  line-height: 32px;
}

.iframe__container {
  position: relative;
  height: 100vh;
  width: 100%;
  background: #1e1e1e;
  display: flex;
  justify-content: center;
  padding-top: 40px;
}
.iframe__full-screen {
  position: fixed;
  top: 0;
  left: 0;
}
.iframe__button {
  border: unset;
  border-radius: 50%;
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b59f6b;
  position: absolute;
  top: 30px;
  right: 30px;
}

@media only screen and (max-width: 991px) {
  .iframe__container {
    height: 90vh;
  }
  .iframe__container-mobile {
    padding-top: 110px !important;
  }
}
@media only screen and (max-width: 767px) {
  .iframe__button {
    right: 10px;
    top: 5px;
  }
}
@media only screen and (max-width: 747px) {
  .iframe__container {
    padding-top: 56px;
  }
}/*# sourceMappingURL=Home.css.map */