.custom-select {
  margin-bottom: 42px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  white-space: nowrap;
}
.custom-select__label {
  margin-right: 16px;
}
.custom-select .css-b62m3t-container {
  width: 100%;
  max-width: 238px;
}
.custom-select .css-b62m3t-container .css-1s2u09g-control .css-319lph-ValueContainer {
  padding-left: 16px;
}
.custom-select .css-b62m3t-container .css-1s2u09g-control .css-1hb7zxy-IndicatorsContainer .css-1okebmr-indicatorSeparator {
  display: none;
}
.custom-select .css-b62m3t-container .css-1pahdxg-control .css-319lph-ValueContainer {
  padding-left: 16px;
}
.custom-select .css-b62m3t-container .css-1pahdxg-control .css-1hb7zxy-IndicatorsContainer .css-1okebmr-indicatorSeparator {
  display: none !important;
}

.custom-table .MuiPaper-root {
  box-shadow: unset;
}
.custom-table .MuiPaper-root .MuiTableContainer-root .MuiTable-root .MuiTableHead-root {
  position: relative;
}
.custom-table .MuiPaper-root .MuiTableContainer-root .MuiTable-root .MuiTableHead-root .MuiTableRow-root {
  white-space: nowrap;
}
.custom-table .MuiPaper-root .MuiTableContainer-root .MuiTable-root .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root {
  border: unset;
  color: #999999;
}
.custom-table .MuiPaper-root .MuiTableContainer-root .MuiTable-root .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:last-child .MuiButtonBase-root {
  padding-left: 20px;
}
.custom-table .MuiPaper-root .MuiTableContainer-root .MuiTable-root .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:nth-child(1) {
  padding-left: 24px;
}
.custom-table .MuiPaper-root .MuiTableContainer-root .MuiTable-root .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:nth-child(1) .MuiButtonBase-root {
  padding-left: 11px;
}
.custom-table .MuiPaper-root .MuiTableContainer-root .MuiTable-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
  font-weight: 500;
  border: unset;
}
.custom-table .MuiPaper-root .MuiTableContainer-root .MuiTable-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:last-child {
  padding-left: 20px;
}
.custom-table .activeHeadCell svg {
  color: #24a3ff !important;
}

.custom-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 60px;
}
.custom-pagination ul li {
  display: flex;
  align-items: center;
}
.custom-pagination ul li .Mui-selected {
  background-color: #ffffff;
  color: #292d32;
  font-weight: bold;
}
.custom-pagination ul li .Mui-selected:hover {
  background-color: #ff0009;
}
.custom-pagination ul li .Mui-disabled {
  opacity: unset;
  background: #ffffff !important;
}
.custom-pagination ul li .Mui-disabled svg {
  color: #292d32 !important;
}
.custom-pagination ul li button {
  padding: 8px;
  background: #ffffff;
  font-weight: 400;
  font-size: 16px;
  color: gray;
  margin: unset;
  border-radius: unset;
}
.custom-pagination ul li button:hover {
  background: #ff0009;
  color: #ffffff;
}
.custom-pagination ul li:first-child {
  transition: 300ms;
  transition-timing-function: linear;
  margin-right: 8px;
}
.custom-pagination ul li:first-child:hover {
  transform: translateX(-5px);
}
.custom-pagination ul li:first-child button {
  background: #ff0009;
  border-radius: 8px;
  padding: unset;
}
.custom-pagination ul li:first-child button svg {
  font-size: 18px;
  color: #ffffff;
}
.custom-pagination ul li:first-child .arrow-disabled {
  background: #ffffff;
  cursor: unset;
}
.custom-pagination ul li:first-child .arrow-disabled svg {
  stroke: #292d32;
  cursor: unset;
}
.custom-pagination ul li:first-child .arrow-active {
  background: #ff0009;
}
.custom-pagination ul li:first-child .arrow-active svg {
  stroke: #ffffff;
}
.custom-pagination ul li:nth-child(2) {
  display: flex;
  align-items: center;
}
.custom-pagination ul li:nth-child(2) button {
  padding: 8px;
  border-radius: 8px 0 0 8px;
}
.custom-pagination ul li:nth-last-child(2) button {
  border-radius: 0 8px 8px 0;
}
.custom-pagination ul li:last-child {
  transition: 300ms;
  transition-timing-function: linear;
  margin-left: 8px;
}
.custom-pagination ul li:last-child:hover {
  transform: translateX(5px);
}
.custom-pagination ul li:last-child button {
  background: #ff0009;
  border-radius: 8px;
  padding: unset;
}
.custom-pagination ul li:last-child button svg {
  font-size: 18px;
  color: #ffffff;
}
.custom-pagination ul li:last-child .arrow-disabled {
  background: #ffffff;
  cursor: unset;
}
.custom-pagination ul li:last-child .arrow-disabled svg {
  stroke: #292d32;
  cursor: unset;
}
.custom-pagination ul li:last-child .arrow-active {
  background: #ff0009;
}
.custom-pagination ul li:last-child .arrow-active svg {
  stroke: #ffffff;
}

.edit-button {
  background: none;
  margin-left: 8px;
}
.edit-button svg {
  color: orange;
}

.barcode {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.barcode__text {
  color: #ff0009;
  text-align: center;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background: #eaf6ff;
  width: 181px;
  min-height: 50px;
  padding: 16px;
  transform: translateY(-10px);
  border: unset;
}
.dropdown-menu__title {
  display: none;
  text-align: center;
}
.dropdown-menu__item {
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.dropdown-menu__button:nth-child(2) {
  margin-top: 10px;
}
.dropdown-menu__button button {
  width: 100%;
  background: #ffffff;
  color: red;
  border-radius: 8px;
  padding: 5px 10px;
  border: 1px solid red;
  white-space: nowrap;
}
.dropdown-menu__button .button-red {
  background: red;
  color: #ffffff;
}

@media only screen and (max-width: 992px) {
  .custom-select {
    flex-direction: column;
    align-items: flex-start;
  }
  .custom-select__label {
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 768px) {
  .dropdown-number {
    display: none;
  }
  .dropdown-menu__title {
    display: block;
  }
}
@media only screen and (max-width: 576px) {
  .custom-select {
    padding: 0 24px;
  }
  .custom-select .css-b62m3t-container {
    max-width: unset;
  }
}/*# sourceMappingURL=style.css.map */