.mobile-menu {
  width: 100%;
}
.mobile-menu__title {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
}
.mobile-menu__logo {
  width: 100px;
  display: block;
}
.mobile-menu__logo img {
  width: 100%;
}
.mobile-menu__close-button {
  background: unset;
  padding: unset;
  stroke: #000000;
}
.mobile-menu__list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-inline: 20px;
  margin-top: 36px;
}
.mobile-menu__list li a {
  color: #323232;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 12px;
}
.mobile-menu__active {
  border-left: 2px solid #FF0009;
}
.mobile-menu__faq button {
  background: unset;
  border: unset;
  color: #323232;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 0 12px;
  cursor: pointer;
}/*# sourceMappingURL=MobileMenu.css.map */