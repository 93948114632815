.input {
  padding: 16px;
  border-radius: 8px;
  background: #F5F5F5;
  border: 1px solid transparent;
  outline: unset;

  &:focus {
    border-color: #000000;
  }
}