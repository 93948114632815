b {
  font-weight: bolder !important;
}

.accordion__item {
  background: #f5f5f5;
  border-radius: 16px;
  margin-bottom: 20px;
  width: 580px;
}
.accordion__item:last-child {
  border: unset;
}
.accordion__text {
  color: #000000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: start;
}
.accordion__button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: unset;
  padding: 16px 16px 8px 16px;
}
.accordion__button svg {
  stroke: #000000;
  flex-shrink: 0;
}
.accordion__description {
  padding: 0 16px 16px 16px;
  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

@media only screen and (max-width: 575px) {
  .accordion__item {
    width: 100%;
  }
}/*# sourceMappingURL=Accordion.css.map */