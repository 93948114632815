.live {
  background: #1e1e1e;

  &__container {
    min-height: 70vh;
    max-width: 1600px;
    margin: auto;
  }
}

.lk-fullscreen {
  position: absolute;
  top: 10px;
  left: 10px;
  background: unset;

  svg {
    color: #FFFFFF;
  }
}

.stream {
  position: relative;
  width: 100%;
  height: 100%;

}