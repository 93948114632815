.results {
  background: #1e1e1e;
  padding-top: 100px;
  min-height: 70vh;

  &__container {
    max-width: 1600px;
    margin: auto;
  }

  &__header {
    display: flex;
    gap: 24px;
    padding-bottom: 24px;
  }

  &__title {
    color: #FFFFFF;
    font-size: 32px;
  }

  &__table {

  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
    width: 100%;
    margin-bottom: 48px;
    &:last-child {
      margin: unset;
      padding-bottom: 48px;
    }
  }

  &__match {
    display: grid;
    align-items: center;
    grid-template-columns: 400px 1fr 400px 1fr;
    width: 100%;
  }

  &__card {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    img {
      width: 180px;
      height: 180px;
      border-radius: 50%;
    }

    &__caption {
      color: #A8A8A8;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 20px;
      font-size: 18px;
    }
  }

  &__versus {
    text-align: center;
    text-transform: uppercase;
    font-size: 24px;
    p:first-child {
      color: #B59F6B;
      margin-bottom: 12px;
    }
    p:last-child {
      color: #A8A8A8;
    }
  }

  &__trophy {
    width: 48px;
    height: 48px;
    background: #B59F6B;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
    display: flex;

    &__container {
      width: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-self: end;
    }

    &__text {
      color: #A8A8A8;
      line-height: 24px;
      text-align: center;
    }
  }
}


@media only screen and (max-width: 1199px) {
  .results {

    &__image {
      position: relative;
    }

    &__card {
      gap: 4px;
      img {
        width: 60px;
        height: 60px;
      }

      &__caption {
        font-size: 12px;
      }
    }

    &__match {
      grid-template-columns: 150px 1fr 150px;
    }

    &__trophy {
      display: flex;
      position: absolute;
      bottom: 0;
      right: 0;
      margin: unset;
      width: 20px;
      height: 20px;

      svg {
        width: 15px;
        height: 15px;
      }

      &__container {
        display: none;
      }
    }

    &__versus {
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .results {
    padding-top: 120px;
  }
}