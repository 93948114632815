.game-list {
  display: flex;
  margin-left: 60px;
  gap: 12px;
}
.game-list__item {
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 8px;
  border-bottom: 2px solid transparent;
  height: 100%;
}
.game-list__item--active {
  border-color: #ff0009;
}
.game-list__text {
  font-size: 14px;
}
.game-list__auth {
  justify-content: space-between;
}

@media only screen and (max-width: 991px) {
  .game-list {
    margin: 0 12px;
  }
  .game-list__item {
    font-size: 12px;
    margin: unset;
  }
  .game-list__text {
    font-size: 8px;
  }
}
@media only screen and (max-width: 556px) {
  .game-list__auth {
    justify-content: center;
    flex-wrap: wrap;
  }
}/*# sourceMappingURL=GameList.css.map */