.auth {
  display: flex;
  height: 100%;
}
.auth__img-container {
  position: relative;
  width: 500px;
  flex-shrink: 0;
  background-image: url("/public/media/images/auth-img.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.auth__img-container img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.auth__img-container svg {
  width: 120px;
  z-index: 1;
  position: absolute;
  top: 20px;
  left: 40px;
}
.auth__img-container a {
  position: absolute;
  bottom: 20px;
  left: 40px;
  color: #FFFFFF;
  font-size: 13px;
  font-weight: 500;
}
.auth__img-container a:hover {
  color: #FFFFFF;
}
.auth__container {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #FFFFFF;
}
.auth__login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 332px;
  width: 100%;
}
.auth__title {
  color: #323232;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  margin-bottom: 4px;
}
.auth__title-red {
  color: var(--topaz-color) !important;
}
.auth__register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
  width: 100%;
}
.auth__text {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.auth__text-gray {
  color: #999999;
}
.auth__logo {
  display: none;
}
.auth__check-register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 366px;
  width: 100%;
}
.auth__info {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #999;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.auth__info button {
  color: var(--blue);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: unset;
}
.auth__forgot-password {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 380px;
  width: 100%;
}
.auth__forgot-password--inner {
  max-width: 320px;
}
.auth__blue-text {
  color: #00AFF5;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.auth__otp-text {
  color: #1E1E1E;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}
.auth__otp-text-underline {
  color: #00AFF5;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
  background: unset;
  border: unset;
}

.showButton {
  position: relative;
}

.showButton-eye {
  position: absolute;
  top: 4%;
  right: 6px;
  transform: translateY(50%);
}

@media only screen and (max-width: 991px) {
  .auth__img-container {
    display: none;
  }
  .auth__container {
    padding-inline: 20px;
    align-items: flex-start;
    padding-top: 20px;
    margin-block: auto;
  }
  .auth__title {
    font-size: 24px;
    text-align: center;
  }
  .auth__logo {
    display: flex;
    justify-content: center;
  }
  .auth__logo img {
    width: 150px;
  }
  .auth__submit {
    width: 100%;
    white-space: nowrap;
  }
  .auth__register-buttons {
    white-space: nowrap;
  }
  .auth__register-buttons button {
    padding: 10px 30px;
  }
}/*# sourceMappingURL=style.css.map */