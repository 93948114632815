.sportsmen {
  background: #1e1e1e;
}
.sportsmen__title {
  color: #FFFFFF;
  font-size: 32px;
}
.sportsmen__container {
  min-height: 70vh;
  padding-top: 100px;
  max-width: 1600px;
  margin: auto;
}
.sportsmen__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px;
  padding-block: 48px;
}
.sportsmen__item {
  display: flex;
  gap: 48px;
}
.sportsmen__item img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.sportsmen__item__title {
  color: #A8A8A8;
  font-size: 24px;
  line-height: 32px;
}
.sportsmen__item__stats {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  margin-top: 24px;
}
.sportsmen__item__stat {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.sportsmen__item__stat p:first-child {
  color: #B59F6B;
}
.sportsmen__item__stat p:last-child {
  color: #FFFFFF;
}

@media only screen and (max-width: 1199px) {
  .sportsmen__list {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 991px) {
  .sportsmen__container {
    padding-top: 120px;
  }
}
@media only screen and (max-width: 768px) {
  .sportsmen__item {
    gap: 16px;
  }
  .sportsmen__item img {
    width: 100px;
    height: 100px;
  }
  .sportsmen__item__title {
    font-size: 12px;
    line-height: 16px;
  }
  .sportsmen__item__stat {
    font-size: 12px;
  }
}/*# sourceMappingURL=Sportsmen.css.map */