.partners {
  background: #1e1e1e;
}
.partners__container {
  padding-top: 100px;
  min-height: 70vh;
  max-width: 1600px;
  margin: auto;
}
.partners__title {
  color: #FFFFFF;
  font-size: 32px;
}
.partners__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 24px;
}
.partners__item {
  display: flex;
  background: #2b2b2b;
  border-radius: 40px;
  flex-direction: column;
  align-items: center;
  padding: 20px 40px;
}
.partners__item img {
  width: 200px;
  height: 200px;
}
.partners__item__title {
  color: #a8a8a8;
  font-size: 24px;
  margin-bottom: 24px;
  display: block;
}
.partners__title {
  color: #FFFFFF;
  font-size: 32px;
  margin-bottom: 24px;
}
.partners__description {
  color: #a8a8a8;
  font-size: 12px;
  max-width: 300px;
  line-height: 16px;
  text-align: center;
}
.partners__caption {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 991px) {
  .partners__container {
    padding-top: 120px;
  }
}
@media only screen and (max-width: 576px) {
  .partners__list {
    grid-template-columns: 1fr;
  }
}/*# sourceMappingURL=Partners.css.map */