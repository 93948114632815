.sportsmen {
  background: #1e1e1e;

  &__title {
    color: #FFFFFF;
    font-size: 32px;
  }

  &__container {
    min-height: 70vh;
    padding-top: 100px;
    max-width: 1600px;
    margin: auto;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 48px;
    padding-block: 48px;
  }

  &__item {
    display: flex;
    gap: 48px;
    img  {
      width: 200px;
      height: 200px;
      border-radius: 50%;
    }

    &__title {
      color: #A8A8A8;
      font-size: 24px;
      line-height: 32px;
    }

    &__stats {
      display: flex;
      gap: 24px;
      justify-content: space-between;
      margin-top: 24px;
    }

    &__stat {
      display: flex;
      flex-direction: column;
      gap: 18px;
      p:first-child {
        color: #B59F6B;
      }
      p:last-child {
        color: #FFFFFF;
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .sportsmen {
    &__list {
      grid-template-columns: 1fr;
    }
  }
}

@media only screen and (max-width: 991px) {
  .sportsmen {
    &__container {
      padding-top: 120px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .sportsmen {
    &__item {
      gap: 16px;

      img {
        width: 100px;
        height: 100px;
      }

      &__title {
        font-size: 12px;
        line-height: 16px;
      }

      &__stat {
        font-size: 12px;
      }
    }
  }
}