.game-list {
  display: flex;
  margin-left: 60px;
  gap: 12px;

  &__item {
    color: #ffffff;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-right: 8px;
    border-bottom: 2px solid transparent;
    height: 100%;

    &--active {
      border-color: #ff0009;
    }
  }

  &__text {
    font-size: 14px;
  }

  &__auth {
    justify-content: space-between;
  }
}

@media only screen and (max-width: 991px) {
  .game-list {
    margin: 0 12px;

    &__item {
      font-size: 12px;
      margin: unset;
    }

    &__text {
      font-size: 8px;
    }
  }
}

@media only screen and (max-width: 556px) {
  .game-list {
    &__auth {
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}
