.documents {
  background: #1e1e1e;
}
.documents__container {
  padding-top: 120px;
  min-height: 70vh;
  max-width: 1600px;
  margin: auto;
}
.documents__title {
  color: #FFFFFF;
  font-size: 32px;
  margin-bottom: 24px;
}
.documents__title-secondary {
  color: #FFFFFF;
  font-size: 24px;
  margin-bottom: 24px;
}
.documents__list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
}
.documents__item {
  border-radius: 16px;
  padding: 24px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  background: #2b2b2b;
}
.documents__item-caption {
  color: #FFFFFF;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.documents__item-download {
  text-decoration: underline;
}
.documents__item-inner {
  display: flex;
  gap: 12px;
}
.documents__load-more {
  background: unset;
  text-decoration: underline;
  color: #FFFFFF;
  padding-bottom: 12px;
}

@media only screen and (max-width: 991px) {
  .documents__list {
    grid-template-columns: 1fr;
  }
}/*# sourceMappingURL=Documents.css.map */