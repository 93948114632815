.home {
  display: flex;
  flex-direction: column;
}

.banner {
  background: #1e1e1e;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 48px;
  padding: 24px 12px;

  img {
    max-width: 750px;
  }

  p {
    color: #ffffff;
    max-width: 388px;
    text-align: center;
    font-size: 24px;
    line-height: 32px;
  }
}

.iframe {
  &__container {
    position: relative;
    height: 100vh;
    width: 100%;
    background: #1e1e1e;
    display: flex;
    justify-content: center;
    padding-top: 40px;
  }

  &__full-screen {
    position: fixed;
    top: 0;
    left: 0;
  }

  &__button {
    border: unset;
    border-radius: 50%;
    width: 52px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #b59f6b;
    position: absolute;
    top: 30px;
    right: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .iframe {
    &__container {
      height: 90vh;
      &-mobile {
        padding-top: 110px !important;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .iframe {
    &__button {
      right: 10px;
      top: 5px;
    }
  }
}

@media only screen and (max-width: 747px) {
  .iframe {
    &__container {
      padding-top: 56px;
    }
  }
}
