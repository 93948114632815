.mobile-menu {
  width: 100%;

  &__title {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    width: 100px;
    display: block;

    img {
      width: 100%;
    }
  }

  &__close-button {
    background: unset;
    padding: unset;
    stroke: #000000;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-inline: 20px;
    margin-top: 36px;

    li {
      a {
        color: #323232;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        padding: 6px 12px;
      }
    }
  }

  &__active {
    border-left: 2px solid #FF0009;
  }

  &__faq {

    button {
      background: unset;
      border: unset;
      color: #323232;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      padding: 0 12px;
      cursor: pointer;
    }
  }
}