.bet-history {
  background: #1e1e1e;
  min-height: 70vh;
  padding-top: 120px;

  &__title {
    color: #FFFFFF;
    font-size: 32px;
  }

  &__count {
    color: #FFFFFF;
    font-size: 14px;
    margin-bottom: 24px;
  }

  &__container {
    max-width: 1600px;
    margin: auto;
  }
}