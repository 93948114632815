.win-history {
  background: #1e1e1e;
  min-height: 70vh;
  padding-top: 120px;
}
.win-history__title {
  color: #FFFFFF;
  font-size: 32px;
}
.win-history__count {
  color: #FFFFFF;
  font-size: 14px;
  margin-bottom: 24px;
}
.win-history__container {
  max-width: 1600px;
  margin: auto;
}/*# sourceMappingURL=WinHistory.css.map */