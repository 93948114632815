.print-ticket {
  max-width: 455px;
  width: 100%;
  background: #FFF;
}
.print-ticket__container {
  position: absolute;
  z-index: -999999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.print-ticket__text-gray {
  color: #999;
  font-size: 12px;
  font-weight: 400;
}
.print-ticket__text-blue {
  color: #24A3FF;
  font-size: 14px;
  font-weight: 500;
}
.print-ticket__text-red {
  color: #FF0009;
  font-size: 14px;
  font-weight: 500;
}
.print-ticket__text-black {
  color: #323232;
  font-size: 14px;
  font-weight: 500;
}
.print-ticket__text-sm {
  font-size: 10px;
}

@media print {
  .print-ticket__container {
    height: auto;
  }
}/*# sourceMappingURL=Print.css.map */