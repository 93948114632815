.results {
  background: #1e1e1e;
  padding-top: 100px;
  min-height: 70vh;
}
.results__container {
  max-width: 1600px;
  margin: auto;
}
.results__header {
  display: flex;
  gap: 24px;
  padding-bottom: 24px;
}
.results__title {
  color: #FFFFFF;
  font-size: 32px;
}
.results__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
  width: 100%;
  margin-bottom: 48px;
}
.results__row:last-child {
  margin: unset;
  padding-bottom: 48px;
}
.results__match {
  display: grid;
  align-items: center;
  grid-template-columns: 400px 1fr 400px 1fr;
  width: 100%;
}
.results__card {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}
.results__card img {
  width: 180px;
  height: 180px;
  border-radius: 50%;
}
.results__card__caption {
  color: #A8A8A8;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 18px;
}
.results__versus {
  text-align: center;
  text-transform: uppercase;
  font-size: 24px;
}
.results__versus p:first-child {
  color: #B59F6B;
  margin-bottom: 12px;
}
.results__versus p:last-child {
  color: #A8A8A8;
}
.results__trophy {
  width: 48px;
  height: 48px;
  background: #B59F6B;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  display: flex;
}
.results__trophy__container {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: end;
}
.results__trophy__text {
  color: #A8A8A8;
  line-height: 24px;
  text-align: center;
}

@media only screen and (max-width: 1199px) {
  .results__image {
    position: relative;
  }
  .results__card {
    gap: 4px;
  }
  .results__card img {
    width: 60px;
    height: 60px;
  }
  .results__card__caption {
    font-size: 12px;
  }
  .results__match {
    grid-template-columns: 150px 1fr 150px;
  }
  .results__trophy {
    display: flex;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: unset;
    width: 20px;
    height: 20px;
  }
  .results__trophy svg {
    width: 15px;
    height: 15px;
  }
  .results__trophy__container {
    display: none;
  }
  .results__versus {
    font-size: 12px;
  }
}
@media only screen and (max-width: 991px) {
  .results {
    padding-top: 120px;
  }
}/*# sourceMappingURL=result.css.map */