.footer {
  background-color: #1e1e1e;
  padding-top: 40px;
}
.footer__container {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 175px 75px 172px 456px;
  padding-bottom: 48px !important;
}
.footer__logo {
  display: flex;
  align-items: flex-start;
  width: 100%;
}
.footer__logo img {
  width: 100%;
}
.footer__logo svg {
  flex-shrink: 0;
}
.footer__logo svg:first-child {
  width: 100px;
  fill: var(--topaz-color);
}
.footer__logo svg:last-child {
  width: 70px;
}
.footer__links ul {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.footer__text {
  color: var(--text-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 8px;
}
.footer__text--gray {
  color: var(--text-gray);
  font-size: 14px;
  line-height: 22px;
}
.footer__text--gray:hover {
  color: var(--text-color);
}
.footer__contact ul {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.footer__contact ul li {
  display: flex;
  gap: 12px;
}
.footer__contact ul li svg {
  fill: var(--text-gray);
}
.footer__contact ul li svg:hover {
  fill: var(--text-color);
}
.footer__contact ul li a {
  display: flex;
  gap: 12px;
}
.footer__contact ul li a:hover svg {
  fill: var(--text-color);
}
.footer__contact ul li a svg {
  fill: var(--text-gray);
}
.footer__contact ul li a svg:hover {
  fill: var(--text-color);
}
.footer__legal-age-inner {
  display: flex;
  gap: 12px;
  margin-bottom: 42px;
}
.footer__legal-age-inner p {
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.footer__legal-age-bottom {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.footer__legal-age-bottom p {
  color: #999999;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}
.footer__legal-age-bottom-flex {
  display: flex;
  gap: 32px;
  align-items: center;
}
.footer__bottom {
  padding-block: 8px;
}
.footer__bottom p {
  color: var(--text-gray);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
}
.footer__crocusoft {
  fill: var(--text-color);
}
.footer__usb-white {
  fill: var(--text-color);
}
.footer__usb-black {
  fill: var(--footer-bg);
}

@media only screen and (max-width: 991px) {
  .footer__container {
    grid-template-columns: 75px 172px;
    grid-template-areas: "logo logo" "links contact" "legal legal";
  }
  .footer__logo {
    grid-area: logo;
    margin-bottom: 30px;
  }
  .footer__links {
    grid-area: links;
  }
  .footer__contact {
    grid-area: contact;
  }
  .footer__legal-age {
    grid-area: legal;
    margin-top: 58px;
  }
  .footer__legal-age-inner {
    margin-bottom: 24px;
  }
}
@media only screen and (max-width: 576px) {
  .footer__logo img {
    width: 50%;
  }
}/*# sourceMappingURL=Footer.css.map */