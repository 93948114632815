.ScanInput {
  border-radius: 12px;
  background: #2b2b2b;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  min-width: 370px;
}
.ScanInput input {
  background: #2b2b2b;
  border: unset;
  color: #7B7575;
  width: 100%;
}
.ScanInput input:focus-visible {
  outline: none;
}
.ScanInput svg {
  flex-shrink: 0;
  margin-right: 8px;
}
.ScanInput button {
  border-radius: 8.25px;
  background: #1e1e1e;
  color: #FFFFFF;
  text-transform: uppercase;
  padding: 10px;
  white-space: nowrap;
  margin-left: auto;
  font-size: 12px;
}

@media only screen and (max-width: 992px) {
  .ScanInput {
    min-width: unset;
  }
}/*# sourceMappingURL=ScanInput.css.map */