@import 'fonts';
@import 'media';

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

* {
  font-family: 'Roboto', serif !important;
  box-sizing: border-box;

  a {
    text-decoration: none;
  }

  button {
    cursor: pointer;
    border: none;
    outline: unset;
  }
}

#root {
  height: 100%;
}

.container {
  max-width: 1240px;
  margin: auto;
  padding: 0 20px;
}

.dropdown-toggle {
  padding: 10px;
  background: var(--dropdown-bg) !important;
  color: var(--dropdown-text) !important;
  box-shadow: unset !important;
  border-radius: 8px;
  border: unset;

  & svg {
    width: 28px;
    height: 28px;
  }
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
}

.dropdown-menu__title {
  color: var(--dropdown-text) !important;
}

.error-text {
  color: var(--red);
}

.bg-gray {
  background-color: var(--light-gray);
}

.br-2 {
  border-radius: 0.5rem;
}

.font-4 {
  font-size: 1rem;
}

.fw-500 {
  font-weight: 500;
}

.text-blue {
  color: var(--blue) !important;
}
.result {
  .MuiTableCell-root {
    padding: 10px !important;
  }
}

.modal-content {
  border-radius: 30px;
}

.modal-body {
  padding: 110px;
}

.modal-dialog {
  max-width: fit-content;
}

.ant-dropdown-menu-item {
  padding: unset !important;
}

.ant-dropdown-menu {
  padding: unset !important;
}

.ant-dropdown-menu-title-content div {
  padding: 8px 12px;
}

.custom-date-picker {
  background: #2b2b2b !important;
  border: initial;
  border-radius: 8px;
  width: 200px;
  padding: 10px;
  > div {
    input {
      color: #ffffff !important;
      &::placeholder {
        color: #ffffff !important;
      }
    }
    span {
      color: #ffffff !important;
    }
  }
}

.ant-dropdown {
  z-index: 1000000000 !important;
}

.MuiPaginationItem-root {
  color: #ffffff !important;
}

.MuiPaginationItem-root.Mui-selected {
  background: #2b2b2b !important;
}

.marquee {
  position: absolute;
  bottom: -4px;
  width: 100%;
  color: red;
  font-size: 14px;
}

.rfm-marquee-container {
  overflow: hidden;
  top: -5px;
}

.lk-room-container {
  padding-top: 80px;
  height: 100%;

  video {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .faq-modal {
    padding: 48px 16px !important;

    &-dialog {
      & div {
        max-width: unset !important;
      }
    }
  }

  .marquee {
    font-size: 12px;
  }

  .lk-room-container {
    height: unset;
  }
}
