@font-face {
  font-family: "Roboto";
  font-weight: 900;
  font-style: normal;
  src: url("/public/fonts/Roboto/Roboto-Black/Roboto-Black.eot");
  src: url("/public/fonts/Roboto/Roboto-Black/Roboto-Black.eot?#iefix") format("embedded-opentype"), url("/public/fonts/Roboto/Roboto-Black/Roboto-Black.woff") format("woff"), url("/public/fonts/Roboto/Roboto-Black/Roboto-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-weight: 900;
  font-style: italic;
  src: url("/public/fonts/Roboto/Roboto-BlackItalic/Roboto-BlackItalic.eot");
  src: url("/public/fonts/Roboto/Roboto-BlackItalic/Roboto-BlackItalic.eot?#iefix") format("embedded-opentype"), url("/public/fonts/Roboto/Roboto-BlackItalic/Roboto-BlackItalic.woff") format("woff"), url("/public/fonts/Roboto/Roboto-BlackItalic/Roboto-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-weight: bold;
  font-style: normal;
  src: url("/public/fonts/Roboto/Roboto-Bold/Roboto-Bold.eot");
  src: url("/public/fonts/Roboto/Roboto-Bold/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("/public/fonts/Roboto/Roboto-Bold/Roboto-Bold.woff") format("woff"), url("/public/fonts/Roboto/Roboto-Bold/Roboto-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-weight: bold;
  font-style: italic;
  src: url("/public/fonts/Roboto/Roboto-BoldItalic/Roboto-BoldItalic.eot");
  src: url("/public/fonts/Roboto/Roboto-BoldItalic/Roboto-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/public/fonts/Roboto/Roboto-BoldItalic/Roboto-BoldItalic.woff") format("woff"), url("/public/fonts/Roboto/Roboto-BoldItalic/Roboto-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-weight: normal;
  font-style: italic;
  src: url("/public/fonts/Roboto/Roboto-Italic/Roboto-Italic.eot");
  src: url("/public/fonts/Roboto/Roboto-Italic/Roboto-Italic.eot?#iefix") format("embedded-opentype"), url("/public/fonts/Roboto/Roboto-Italic/Roboto-Italic.woff") format("woff"), url("/public/fonts/Roboto/Roboto-Italic/Roboto-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-weight: 300;
  font-style: normal;
  src: url("/public/fonts/Roboto/Roboto-Light/Roboto-Light.eot");
  src: url("/public/fonts/Roboto/Roboto-Light/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("/public/fonts/Roboto/Roboto-Light/Roboto-Light.woff") format("woff"), url("/public/fonts/Roboto/Roboto-Light/Roboto-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-weight: 300;
  font-style: italic;
  src: url("/public/fonts/Roboto/Roboto-LightItalic/Roboto-LightItalic.eot");
  src: url("/public/fonts/Roboto/Roboto-LightItalic/Roboto-LightItalic.eot?#iefix") format("embedded-opentype"), url("/public/fonts/Roboto/Roboto-LightItalic/Roboto-LightItalic.woff") format("woff"), url("/public/fonts/Roboto/Roboto-LightItalic/Roboto-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-weight: 500;
  font-style: normal;
  src: url("/public/fonts/Roboto/Roboto-Medium/Roboto-Medium.eot");
  src: url("/public/fonts/Roboto/Roboto-Medium/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("/public/fonts/Roboto/Roboto-Medium/Roboto-Medium.woff") format("woff"), url("/public/fonts/Roboto/Roboto-Medium/Roboto-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-weight: 500;
  font-style: italic;
  src: url("/public/fonts/Roboto/Roboto-MediumItalic/Roboto-MediumItalic.eot");
  src: url("/public/fonts/Roboto/Roboto-MediumItalic/Roboto-MediumItalic.eot?#iefix") format("embedded-opentype"), url("/public/fonts/Roboto/Roboto-MediumItalic/Roboto-MediumItalic.woff") format("woff"), url("/public/fonts/Roboto/Roboto-MediumItalic/Roboto-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-weight: normal;
  font-style: normal;
  src: url("/public/fonts/Roboto/Roboto-Regular/Roboto-Regular.eot");
  src: url("/public/fonts/Roboto/Roboto-Regular/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("/public/fonts/Roboto/Roboto-Regular/Roboto-Regular.woff") format("woff"), url("/public/fonts/Roboto/Roboto-Regular/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-weight: 100;
  font-style: normal;
  src: url("/public/fonts/Roboto/Roboto-Thin/Roboto-Thin.eot");
  src: url("/public/fonts/Roboto/Roboto-Thin/Roboto-Thin.eot?#iefix") format("embedded-opentype"), url("/public/fonts/Roboto/Roboto-Thin/Roboto-Thin.woff") format("woff"), url("/public/fonts/Roboto/Roboto-Thin/Roboto-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-weight: 100;
  font-style: italic;
  src: url("/public/fonts/Roboto/Roboto-ThinItalic/Roboto-ThinItalic.eot");
  src: url("/public/fonts/Roboto/Roboto-ThinItalic/Roboto-ThinItalic.eot?#iefix") format("embedded-opentype"), url("/public/fonts/Roboto/Roboto-ThinItalic/Roboto-ThinItalic.woff") format("woff"), url("/public/fonts/Roboto/Roboto-ThinItalic/Roboto-ThinItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Freshman";
  font-weight: 400;
  font-style: normal;
  src: url("/public/fonts/Freshman/Freshman.eot");
  src: url("/public/fonts/Freshman/Freshman.eot?#iefix") format("embedded-opentype"), url("/public/fonts/Freshman/Freshman.woff") format("woff"), url("/public/fonts/Freshman/Freshman.ttf") format("truetype");
}
/*
   Enter the variables here.
   -----
   Example: "Grids"
*/
:root {
  --header-bg: #FFFFFF;
  --footer-bg: #FFFFFF;
  --footer-border: #FFFFFF;
  --text-color: #323232;
  --text-gray: #999999;
  --topaz-color: #FF0009;
  --light-gray: #F6F6F6;
  --dropdown-bg: #eaf6ff;
  --dropdown-text: #999999;
  --blue: #24A3FF;
  --red: #FF0009;
}

[mode=dark] {
  --header-bg: transparent;
  --footer-bg: #000000;
  --footer-border: #373737;
  --text-color: #FFFFFF;
  --topaz-color: #FFFFFF;
  --text-gray: #999999;
  --light-gray: #F6F6F6;
  --dropdown-bg: rgba(36, 163, 255, 0.20);
  --dropdown-text: #999999;
}

@media only screen and (max-width: 992px) {
  .home .img-container {
    display: none;
  }
  .home .auth-container {
    width: 100%;
    height: 100vh;
    padding-top: 64px;
    align-items: unset;
  }
  .home .auth-container .login {
    width: 375px;
  }
  .home .auth-container .login .logo {
    display: block;
  }
  .home .auth-container .login .section-1 {
    width: 199px;
    height: 28px;
    margin: auto;
    margin-top: 20px;
  }
  .home .auth-container .login .section-1 p span {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
  }
  .home .auth-container .login .section-1 form {
    width: 100%;
  }
  .home .auth-container .login .section-2 {
    display: flex;
    justify-content: center;
  }
  .home .auth-container .login .section-5 button {
    width: 100%;
  }
  .home .auth-container .register {
    max-width: 335px;
    padding: 0 20px;
  }
  .home .auth-container .register .logo {
    display: block;
  }
  .home .auth-container .register .section-1 {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .home .auth-container .register .section-1 span {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
  }
  .home .auth-container .register .section-2 {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .home .auth-container .register .section-2 span {
    text-align: center;
  }
  .home .auth-container .register .section-4 {
    flex-direction: column;
  }
  .home .auth-container .register .section-4-item button {
    width: 100%;
  }
  .home .auth-container .register .section-4-item:nth-child(2) {
    margin-top: 20px;
  }
  .home .auth-container .check-register {
    max-width: 335px;
    padding: 0 20px;
  }
  .home .auth-container .check-register .logo {
    display: block;
  }
  .home .auth-container .check-register .section-1 {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .home .auth-container .check-register .section-1 span {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
  }
  .home .auth-container .check-register .section-2 {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .home .auth-container .check-register .section-2 span {
    text-align: center;
  }
  .home .auth-container .check-register .section-6 button {
    width: 100%;
  }
  .home .auth-container .forgot-pass {
    max-width: 335px;
    padding: 0 20px;
  }
  .home .auth-container .forgot-pass .logo {
    display: block;
  }
  .home .auth-container .forgot-pass .section-1 h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #323232;
  }
  .home .auth-container .forgot-pass .section-2 {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .home .auth-container .forgot-pass .section-2 span {
    text-align: center;
  }
  .home .auth-container .forgot-pass .section-3-item {
    max-width: unset;
  }
  .home .auth-container .forgot-pass .section-3-item input {
    width: 100%;
  }
  .home .auth-container .forgot-pass .section-4 {
    max-width: unset;
    flex-direction: column;
  }
  .home .auth-container .forgot-pass .section-4-item button {
    width: 100%;
  }
  .home .auth-container .forgot-pass .section-4-item:nth-child(2) {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 376px) {
  .home .auth-container > div {
    width: 100%;
  }
  .home .auth-container > div .login {
    width: 100%;
  }
  .home .auth-container > div .register {
    width: 100%;
    margin: auto;
  }
}
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

* {
  font-family: "Roboto", serif !important;
  box-sizing: border-box;
}
* a {
  text-decoration: none;
}
* button {
  cursor: pointer;
  border: none;
  outline: unset;
}

#root {
  height: 100%;
}

.container {
  max-width: 1240px;
  margin: auto;
  padding: 0 20px;
}

.dropdown-toggle {
  padding: 10px;
  background: var(--dropdown-bg) !important;
  color: var(--dropdown-text) !important;
  box-shadow: unset !important;
  border-radius: 8px;
  border: unset;
}
.dropdown-toggle svg {
  width: 28px;
  height: 28px;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
}

.dropdown-menu__title {
  color: var(--dropdown-text) !important;
}

.error-text {
  color: var(--red);
}

.bg-gray {
  background-color: var(--light-gray);
}

.br-2 {
  border-radius: 0.5rem;
}

.font-4 {
  font-size: 1rem;
}

.fw-500 {
  font-weight: 500;
}

.text-blue {
  color: var(--blue) !important;
}

.result .MuiTableCell-root {
  padding: 10px !important;
}

.modal-content {
  border-radius: 30px;
}

.modal-body {
  padding: 110px;
}

.modal-dialog {
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.ant-dropdown-menu-item {
  padding: unset !important;
}

.ant-dropdown-menu {
  padding: unset !important;
}

.ant-dropdown-menu-title-content div {
  padding: 8px 12px;
}

.custom-date-picker {
  background: #2b2b2b !important;
  border: initial;
  border-radius: 8px;
  width: 200px;
  padding: 10px;
}
.custom-date-picker > div input {
  color: #ffffff !important;
}
.custom-date-picker > div input::-moz-placeholder {
  color: #ffffff !important;
}
.custom-date-picker > div input::placeholder {
  color: #ffffff !important;
}
.custom-date-picker > div span {
  color: #ffffff !important;
}

.ant-dropdown {
  z-index: 1000000000 !important;
}

.MuiPaginationItem-root {
  color: #ffffff !important;
}

.MuiPaginationItem-root.Mui-selected {
  background: #2b2b2b !important;
}

.marquee {
  position: absolute;
  bottom: -4px;
  width: 100%;
  color: red;
  font-size: 14px;
}

.rfm-marquee-container {
  overflow: hidden;
  top: -5px;
}

.lk-room-container {
  padding-top: 80px;
  height: 100%;
}
.lk-room-container video {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .faq-modal {
    padding: 48px 16px !important;
  }
  .faq-modal-dialog div {
    max-width: unset !important;
  }
  .marquee {
    font-size: 12px;
  }
  .lk-room-container {
    height: unset;
  }
}/*# sourceMappingURL=global.css.map */