.primary-button {
  border-radius: 30px;
  background: #1e1e1e;
  padding: 10px 50px;
  color: #FFFFFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  border: 1px solid transparent;
}
.primary-button-outline {
  background-color: #FFFFFF;
  color: #000000;
  border-color: #1e1e1e;
}/*# sourceMappingURL=PrimaryButton.css.map */