.documents {
  background: #1e1e1e;

  &__container {
    padding-top: 120px;
    min-height: 70vh;
    max-width: 1600px;
    margin: auto;
  }

  &__title {
    color: #FFFFFF;
    font-size: 32px;
    margin-bottom: 24px;

    &-secondary {
      color: #FFFFFF;
      font-size: 24px;
      margin-bottom: 24px;
    }

  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
  }

  &__item {
    border-radius: 16px;
    padding: 24px 16px;
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    background: #2b2b2b;

    &-caption {
      color: #FFFFFF;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-download {
      text-decoration: underline;
    }

    &-inner {
      display: flex;
      gap: 12px;
    }
  }

  &__load-more {
    background: unset;
    text-decoration: underline;
    color: #FFFFFF;
    padding-bottom: 12px;
  }
}

@media only screen and (max-width: 991px) {
  .documents {
    &__list {
      grid-template-columns: 1fr;
    }
  }
}